import { Box, Button, Menu, MenuItem, ListItemIcon } from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Diamond, Info, Token } from '@mui/icons-material';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import TokenOutlinedIcon from '@mui/icons-material/TokenOutlined';
import { HexagonButton } from './HexagonButton';
import HexagonButtonWithBorder from './HexagonButtonWithBorder';

const LandingNavBar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ width: '100%', padding: { md: '10px 60px', lg: '10px 150px' }, display: 'inline-flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <RouterLink to="/home">
        <Box
          component="img"
          alt=""
          src="/static/landingPage/logos/colorfulLogo.svg"
          sx={{ width: 175, height: 70, cursor: 'pointer' }}
        />
      </RouterLink>

      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Button
          onClick={handleClick}
          sx={{ color: 'white' }}
        >
          {t("learnMore")}
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem onClick={() => navigate('/home/learn-more')}>
            <ListItemIcon>
              <Diamond />
            </ListItemIcon>
            {t('ourDiamonds')}
          </MenuItem>
          <MenuItem onClick={() => navigate('/home/company')}>
            <ListItemIcon>
              <Info />
            </ListItemIcon>
            {t('whoWeAre')}
          </MenuItem>
          <MenuItem onClick={() => navigate('/home/landingPageV2')}>
            <ListItemIcon>
              <BusinessCenterIcon />
            </ListItemIcon>
            {t('whatWeDo')}
          </MenuItem>
          <MenuItem onClick={() => navigate('/home/token-details')}>
            <ListItemIcon>
              <TokenOutlinedIcon />
            </ListItemIcon>
            {t('tokenDetails')}
          </MenuItem>
        </Menu>

        {/* Other buttons */}
        <HexagonButton onClick={() => navigate('/home/get-started')} sx={{ ml: 3, color: 'black' }}>{t("getStartedUppercase")}</HexagonButton>
        <HexagonButtonWithBorder onClick={() => navigate('/marketplace')} sx={{ ml: 3 }} title="Marketplace" textSx={{ fontSize: '1.1rem' }} />
        <HexagonButtonWithBorder onClick={() => navigate('/home/login')} sx={{ ml: 3 }} title={t("logIn")} textSx={{ fontSize: '1.1rem' }} />
      </Box>
    </Box>
  );
};

export default LandingNavBar;
